/*
  This file is intended to be overwritten. It provides a common place to store
  site configuration data.
*/

const config = {
  title: 'ABC Web Wallet',
  titleShort: 'ABC',
  balanceText: 'ABC Balance',
  balanceIcon: 'fab-bitcoin'
}

module.exports = config
