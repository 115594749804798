import React from "react"
import { Sidebar } from "adminlte-2-react"

import Wallet from "./admin-lte/wallet"
import Tokens from "./admin-lte/tokens"
import Configure from "./admin-lte/configure"
import SendReceive from "./admin-lte/send-receive"
import Sweep from './sweep'

const { Item } = Sidebar

const MenuComponents = props => {
  return [
    {
      key: "Send/Receive ABC",
      component: <SendReceive key="Send/Receive ABC" {...props} />,
      menuItem: (
        <Item
          icon="fa-exchange-alt"
          key="Send/Receive ABC"
          text="Send/Receive ABC"
        />
      ),
    },
    {
      key: "Wallet",
      component: <Wallet key="Wallet" {...props} />,
      menuItem: <Item icon="fa-wallet" key="Wallet" text="Wallet" />,
    },
    {
      key: "Tokens",
      component: <Tokens key="Tokens" {...props} />,
      menuItem: <Item icon="fas-coins" key="Tokens" text="Tokens" />,
    },
    {
      key: "Sweep",
      component: <Sweep key='Sweep' {...props} />,
      menuItem: <Item icon='fas-arrow-circle-up' key='Sweep' text='Sweep' />
    },
    {
      key: "Configure",
      component: <Configure key="Configure" {...props} />,
      menuItem: <Item icon="fas-cog" key="Configure" text="Configure" />,
    },
  ]
}

export default MenuComponents
